import SecureLS from 'secure-ls';
import { AUTHAPI } from './axios';
import { URLS } from './urls/urls';

const ls = new SecureLS({ encodingType: 'aes' });

export const refreshToken = async () => {
    let token = ls.get('user-details');
    const newToken = await AUTHAPI.post(URLS.AUTHS.REFRESH, {
        refresh_token: token.refresh_token
    })
        .then((resp) => {
            token.access_token = resp.data.access_token;
            ls.set('user-details', token);
            return resp.data.access_token;
        })
        .catch(() => {
            ls.set('redirect', window.location.pathname.replace('/', ''));
            ls.remove('user-details');
            // window.location.pathname = '/login';
        });
    return newToken;
};

import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { API } from 'services/axios';
import { URLS } from 'services/urls/urls';
import {toast} from 'react-toastify'
import {useNavigate} from 'react-router-dom'
import LoadingButton from "@mui/lab/LoadingButton";

export default function DeleteUSerAccount({user}) {
  const [open, setOpen] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [resetingPW, setResetingPW] = React.useState(false);

  const navigate = useNavigate()

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleResetPassword = () => {
    setResetingPW(true);
    API.delete(URLS.USERS.DELETE(user?.id), {})
      .then((resp) => {
        toast.success(`The user account has been deleted`);
        setResetingPW(false);
        navigate("/users/list")
      })
      .catch((error) => {
        toast.error(error?.response?.data.detail ?? "User deletion failed !")
        setResetingPW(false);
      });
  }

  return (
    <div>
      <Button variant="contained" color="error" onClick={handleClickOpen}>
        Delete user account
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Account deletion ({user.first_name + " " + user?.last_name})</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to delete user {user.first_name + " " + user?.last_name}. <br/>
            All items related to this user, including <strong>Wallets, Email addresses, Transactions, Notifications, Positions, Bank details, Uploaded files, Comments, Tickets, Call request, etc...</strong> will be deleted.
            <br/>
            <Typography color="error">Are sure you want to continue ?</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton loading={resetingPW} variant="contained" color="error" onClick={handleResetPassword}>Yes, delete the user</LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

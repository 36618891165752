import { API } from "services/axios";
import { URLS } from "services/urls/urls";
import { toast } from "react-toastify";
import {
  convertProspectFailed,
  convertProspectRequest,
  convertProspectSuccess,
  createProspectFailed,
  createProspectRequest,
  createProspectSuccess,
  deleteProspectFailed,
  deleteProspectRequest,
  deleteProspectSuccess,
  editProspectFailed,
  editProspectRequest,
  editProspectSuccess,
  listProspectFailed,
  listProspectRequest,
  listProspectSuccess,
} from "store/reducers/prospects";

export const createProspectEffect = (datas, handleClose) => {
  return (dispatch) => {
    dispatch(createProspectRequest());
    API.post(URLS.USERS.PROSPECTS.CREATE, datas)
      .then((resp) => {
        const { data } = resp;
        dispatch(createProspectSuccess(data));
        toast.success("Prospect successfully created");
        handleClose();
      })
      .catch((error) => {
        toast.error(
          error.response.data.detail ??
            "Prospect creation failed, please try later."
        );
        dispatch(createProspectFailed());
      });
  };
};

// List prospects
export const listProspectEffect = (search, pageSize, page, toConvert) => {
  return (dispatch) => {
    dispatch(listProspectRequest());
    API.get(URLS.USERS.PROSPECTS.LIST(search, pageSize, page, toConvert))
      .then((resp) => {
        const { data } = resp;
        dispatch(listProspectSuccess(data));
      })
      .catch((error) => {
        toast.error(
          error.response.data.detail ??
            "Loading prospects error, please try later."
        );
        dispatch(listProspectFailed());
      });
  };
};

// edit prospect datas
export const editProspectEffect = (prospect, onSuccess) => {
  return (dispatch) => {
    dispatch(editProspectRequest());
    API.patch(URLS.USERS.PROSPECTS.UPDATE(prospect.id), prospect)
      .then((resp) => {
        const { data } = resp;
        dispatch(editProspectSuccess(data));
        toast.success("Prospect data successfully updated");
        if (onSuccess) {
          onSuccess(data.id);
        }
      })
      .catch((error) => {
        toast.error(
          error.response.data.detail ??
            "Prospects edition error, please try later."
        );
        dispatch(editProspectFailed());
      });
  };
};

// convert prospect
export const convertProspectEffect = (prospect) => {
  return (dispatch) => {
    // const id = toast.loading("Converting the prospect...");
    dispatch(convertProspectRequest());
    API.put(URLS.USERS.PROSPECTS.ADMIN_CONVERT(prospect.id), prospect)
      .then((resp) => {
        const { data } = resp;
        dispatch(convertProspectSuccess(prospect));
        // toast.update(id, {
        //     render: "Prospect successfully turned to client.",
        //     type: "success",
        //     isLoading: false,
        //     autoClose: 5000,
        //   });
      })
      .catch((error) => {
        // toast.update(id, {
        //     render: error?.response?.data?.detail ?? "Prospect convertion failed, please try later.",
        //     type: "error",
        //     isLoading: false,
        //     autoClose: 5000,
        //   });
        dispatch(convertProspectFailed());
      });
  };
};

// delete prospect
export const deleteProspectEffect = (prospect, onSuccess) => {
  return (dispatch) => {
    dispatch(deleteProspectRequest());
    API.delete(URLS.USERS.PROSPECTS.UPDATE(prospect.id))
      .then((resp) => {
        const { data } = resp;
        dispatch(deleteProspectSuccess(prospect));
        toast.success("Prospect data successfully deleted");
        if (onSuccess) {
          onSuccess(prospect.id);
        }
      })
      .catch((error) => {
        toast.error(
          error.response.data.detail ??
            "Prospects deletion error, please try later."
        );
        dispatch(deleteProspectFailed());
      });
  };
};

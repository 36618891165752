import { API } from 'services/axios';
import { URLS } from 'services/urls/urls';
import { toast } from 'react-toastify';
import { loadingCountriesFailed, loadingCountriesRequest, loadingCountriesSuccess } from 'store/reducers/countries';

export const loadingCountriesEffect = () => {
    return (dispatch) => {
        dispatch(loadingCountriesRequest());
        API.get(URLS.COUNTRIES.LIST)
            .then((resp) => {
                const { data } = resp;
                dispatch(loadingCountriesSuccess(data.results));
            })
            .catch((error) => {
                toast.error(error.response.data.detail ?? 'Login failed with provided credentials');
                dispatch(loadingCountriesFailed());
            });
    };
};

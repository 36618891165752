import { CopyToClipboard } from "react-copy-to-clipboard";
// material-ui
import { IconButton, Tooltip } from "@mui/material";

import { CodeOutlined, CopyOutlined } from '@ant-design/icons';

const CopyButton = ({ text, title }) => {
  return (
    <CopyToClipboard text={text}>
      <Tooltip title={title} placement="top-end">
        <IconButton
          color="secondary"
          size="small"
          sx={{ fontSize: "0.875rem" }}
        >
          <CopyOutlined />
        </IconButton>
      </Tooltip>
    </CopyToClipboard>
  );
};

export default CopyButton;

// material-ui
import {
  Button,
  FormHelperText,
  Grid,
  IconButton,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  LinearProgress,
  Box,
  Table,
  TableContainer,
} from "@mui/material";
import MainCard from "components/MainCard";
import { useEffect, useState } from "react";
import { API } from "services/axios";
import { URLS } from "services/urls/urls";
import GroupListActions from "./components/GroupListActions";
import NewUserGroup from "./components/NewUserGroup";

export default function GroupList() {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleCreateUserSuccess = (data, isEdition) => {
    if (isEdition) {
      setGroups((prev) => {
        return prev.map((group) => {
          if (group.id === data.id) {
            group = data;
          }
          return group;
        });
      });
    } else {
      setGroups((prev) => [data, ...prev]);
    }
  };

  const onDeleteSuccess = (id) => {
    setGroups((prev) => {
      return prev.filter(group => group.id != id);
    })
  }

  useEffect(() => {
    API.get(URLS.PERMISSIONS.GROUPS.LIST).then((resp) => {
      const { data } = resp;
      setGroups(data.results);
      setLoading(false)
    }).catch((error) => {

      setLoading(false)
    });
  }, []);
  return (
    <Grid container>
      <Grid item md={12} xs={12}>
        <MainCard
          title={
            <Box sx={{ display: "flex" }}>
              <Box sx={{ marginRight: "auto" }}>
                {"Users groups and permissions"}
              </Box>
              <Box>
                {/* <NewBankDepositDetails
                  onSuccess={handleCreateBankDetailsSuccess}
                  isEdition={false}
                /> */}
                <NewUserGroup onSuccess={handleCreateUserSuccess} />
              </Box>
            </Box>
          }
        >
          {loading && <LinearProgress />}
          {!loading && (
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Group Name</TableCell>
                    <TableCell>Permissions</TableCell>
                    {/* <TableCell>Swift</TableCell>
                    <TableCell>Created At</TableCell> */}
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groups.map((row) => (
                    <TableRow
                      key={"group" + row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell>{row.permissions.slice(0,5).map(perm => (<span>{perm.name}, </span>))}...</TableCell>
                      <TableCell>
                        <GroupListActions group={row} onSuccess={handleCreateUserSuccess} onDeleteSuccess={onDeleteSuccess}/>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </MainCard>
      </Grid>
    </Grid>
  );
}

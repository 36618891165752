import * as React from "react";
import { Grid, Card, Avatar, LinearProgress, Box } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { API } from "services/axios";
import { URLS } from "services/urls/urls";
import { useParams } from "react-router";
import Palette from "themes/palette";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import UpdatePersonnal from "../users/details/components/UpdatePersonnal";
import { dispatch } from "store/index";
import { useDispatch } from "react-redux";
import { loadingCountriesEffect } from "store/effects/countriesEffects";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Profile() {
  const { userId } = useParams();
  const theme = Palette("light", "default");

  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    API.get(URLS.USERS.ME)
      .then((response) => {
        const { data } = response;
        setUserDetails(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
    dispatch(loadingCountriesEffect())
  }, []);

  return (
    // <MainCard title={"User details"}>
    // </MainCard>
    <Grid container>
      <Grid item md={12}>
        {loading && <LinearProgress />}
      </Grid>
      {!loading && (
        <Grid item md={12}>
          <Card
            sx={{
              height: "300px",
              display: "flex",
              bgcolor: theme.palette.primary.main,
              borderRadius: '1rem',
            }}
            color="primary"
            variant="outlined"
          >
            <Box mt={"auto"} ml={"1rem"} mb={"1rem"}>
              <Avatar
                sx={{ bgcolor: theme.palette.secondary.main, height: '100px', width: '100px' }}
                src={userDetails?.image}
                alt={userDetails?.first_name + " " + userDetails?.last_name}
              >
                {userDetails?.first_name[0] + userDetails?.last_name[0]}
              </Avatar>
            </Box>
          </Card>
        </Grid>
      )}
      {!loading && userDetails && <Grid item md={12}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", margin: '1rem 0' }}>
          </Box>
          <Typography variant="h4">Personnal informations</Typography>
            <UpdatePersonnal prospect={userDetails} groups={[]}/>
        </Box>
      </Grid>}
    </Grid>
  );
}

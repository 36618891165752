import axios from 'axios'
import { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useNavigate } from 'react-router'
import SecureLS from 'secure-ls'
import { URLS } from 'services/urls/urls'

let ls = new SecureLS({encodingType: 'aes'})

export default function DisconnectInactiveUser({children}) {

  const [count, setCount] = useState(0)
  const [remaining, setRemaining] = useState(0)

  const navigate = useNavigate();
  const onIdle = () => {
    // Logout user
    axios
      .get(URLS.BASE_URL + "/" + URLS.AUTHS.LOGOUT, {
        headers: { "Client-Id": "1I8AycdsKkJiieicog43pudIkVPA6gZRWR736SRM" },
      })
      .then((reps) => {
        ls.removeAll();
        navigate("/login");
      });
  }

  const onActive = () => {
  }

  const onAction = () => {
    setCount(count + 1)
  }

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 1_200_000,
    throttle: 300_000,
    events: [
      'mousemove', 
      'keydown', 
      'wheel', 
      'DOMMouseScroll', 
      'mousewheel', 
      'mousedown', 
      'touchstart', 
      'touchmove', 
      'MSPointerDown', 
      'MSPointerMove', 
    ]
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  }, [])

    return children
}
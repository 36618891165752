// ==============================|| OVERRIDES - TYPOGRAPHY ||============================== //

export default function Typography() {
    return {
        MuiTypography: {
            styleOverrides: {
                gutterBottom: {
                    marginBottom: 12
                },
                noWrap: {
                    whiteSpace: "initial",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical",
                }
            }
        }
    };
}

// material-ui
import { Typography } from "@mui/material";

// project import
import MainCard from "components/MainCard";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadingCountriesEffect } from "store/effects/countriesEffects";
import { loadingUsers } from "store/effects/usersEffect";
import {
  convertProspectEffect,
  deleteProspectEffect,
  editProspectEffect,
  listProspectEffect,
} from "store/effects/prospectEffects";
// import CreateProspect from './createProspect';
import { LinearProgress, CircularProgress } from "@mui/material";

import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Tooltip from "@mui/material/Tooltip";
// material-ui
import {
  Box,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  TextField,
  Checkbox,
  Select,
  Grid,
} from "@mui/material";

// Icons
import {
  DeleteOutlined,
  EditOutlined,
  ArrowDownOutlined,
  EyeOutlined,
} from "@ant-design/icons";

// project import
import Dot from "components/@extended/Dot";
import _ from "lodash";
// import EditProspect from './editProspect';
import LoadingButton from "@mui/lab/LoadingButton";
import SecureLS from "secure-ls";
import moment from "moment";
import { Link } from "react-router-dom";
import { API } from "services/axios";
import { URLS } from "services/urls/urls";
import { useParams } from "react-router";
import NewUserFile from "./create/NewUserFile";
import CopyButton from "components/@extended/CopyButton";

// import {}

let ls = new SecureLS({ encodingType: "aes" });
const label = { inputProps: { "aria-label": "Checkbox demo" } };

// ==============================|| PROSPECTS LIST ||============================== //

function OrderTableHead({ handleSelectAll, isAllSelected }) {
  return (
    <TableHead>
      <TableRow>
        <TableCell>
          {" "}
          <Checkbox
            {...label}
            checked={isAllSelected}
            onClick={() => handleSelectAll(isAllSelected)}
          />
        </TableCell>
        <TableCell>File type</TableCell>
        <TableCell>File link</TableCell>
        {/* <TableCell>Description</TableCell> */}
        <TableCell>Created at</TableCell>
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

OrderTableHead.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
};

// ==============================|| ORDER TABLE - STATUS ||============================== //

const OrderStatus = ({ status }) => {
  let color;
  let title;

  switch (status) {
    case 0:
      color = "warning";
      title = "Pending";
      break;
    case 1:
      color = "success";
      title = "Approved";
      break;
    case 2:
      color = "error";
      title = "Rejected";
      break;
    default:
      color = "primary";
      title = "None";
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Dot color={color} />
      <Typography>{title}</Typography>
    </Stack>
  );
};

OrderStatus.propTypes = {
  status: PropTypes.number,
};

// ==============================|| ORDER TABLE ||============================== //

export default function UserFilesList() {
  const [order] = useState("asc");
  const [orderBy] = useState("trackingNo");
  const [selected] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [selectedProspect, setSelectedProspect] = useState([]);
  // const [selectedProspect, setSelectedProspect] = useState([]);
  const [search, setSearch] = useState("");
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [open, setOpen] = useState({ open: false, id: "" });
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);

  const { userId } = useParams();

  const {
    loading_users_request,
    delete_propect_success,
    delete_propect_request,

    usersList: { results, count, page: page1 },
  } = useSelector((state) => state.users);
  const { countriesList } = useSelector((state) => state.countries);

  // console.log('user ===========> ', user);
  const handleSelectProspect = (prospect) => {
    setSelectedProspect((prev) => [...prev, prospect.id]);
    if (selectedProspect.length === results.length) {
      setIsAllSelected(true);
    }

    // setIsAllSelected(true);
  };
  const handleSelectAllProspects = (isAllSelected) => {
    if (isAllSelected) {
      setSelectedProspect([]);
      setIsAllSelected(false);
    } else {
      setIsAllSelected(true);
      results.forEach((prospect) => {
        handleSelectProspect(prospect, true);
      });
    }
  };
  const dispatch = useDispatch();
  const handleSuccess = () => {
    API.get(URLS.USERS.FILES.USER(userId))
      .then((resp) => {
        setTransactions(resp.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    dispatch(loadingCountriesEffect());
    API.get(URLS.USERS.FILES.USER(userId))
      .then((resp) => {
        setTransactions(resp.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);
  useEffect(() => {
    if (delete_propect_success) {
      setOpen({ open: false, id: "" });
    }
  }, [delete_propect_success]);

  const isSelected = (trackingNo) => selected.indexOf(trackingNo) !== -1;
 
  return (
    <Grid container>
      {loading && <LinearProgress />}
      {/* {list_propect_request && results.length === 0 && <LinearProgress />} */}
      <Grid item md={12}>
        <Stack justifyContent="end">
          <NewUserFile
            handleSuccess={handleSuccess}
            actionButton={() => (
              <Button
                variant="contained"
                sx={{ width: "200px", marginLeft: "auto" }}
                color="primary"
              >
                New User file
              </Button>
            )}
          />
        </Stack>
      </Grid>
      <Box>
        {!loading && transactions.length > 0 && (
          <TableContainer
            sx={{
              width: "100%",
              overflowX: "auto",
              position: "relative",
              display: "block",
              maxWidth: "100%",
              "& td, & th": { whiteSpace: "nowrap" },
            }}
          >
            <Table
              aria-labelledby="tableTitle"
              sx={{
                "& .MuiTableCell-root:first-child": {
                  pl: 2,
                },
                "& .MuiTableCell-root:last-child": {
                  pr: 3,
                },
              }}
            >
              <OrderTableHead
                order={order}
                orderBy={orderBy}
                handleSelectAll={handleSelectAllProspects}
                isAllSelected={isAllSelected}
              />
              <TableBody>
                {transactions?.map((row1, index) => {
                  const row = { ...row1 };
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${row.id}`;
                  row.countryDetails = _.filter(countriesList, (item) =>
                    item.url.includes(`/${row.country}/`)
                  )[0];

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      aria-checked={selectedProspect.includes(row.id)}
                      tabIndex={-1}
                      key={row.id}
                      selected={selectedProspect.includes(row.id)}
                    >
                      <TableCell>
                        {" "}
                        <div/>
                      </TableCell>
                      <TableCell align="left">{row.file_type}</TableCell>
                      <TableCell align="left">
                        <div style={{width: '300px', display: 'flex'}}>
                        <Typography sx={{width: '250px', overflow: 'hidden', textOverflow: 'ellipsis'}}>

                        {row.file}
                        </Typography>
                        {" "}
                        <CopyButton text={row.file} title={"Copy the file link"} />
                        </div>
                        </TableCell>
                      {/* <TableCell align="left">{row.description}</TableCell> */}
                      <TableCell align="left">{row.uploaded_at}</TableCell>
                      
                      <TableCell align="left" sx={{ display: "flex" }}>
                        {/* {edit_propect_request &&
                      selectedProspect.includes(row.id) ? (
                        <CircularProgress size={20} />
                      ) : (
                        <Tooltip title="View more details">
                          <IconButton
                          component={Link}
                          to={`/users/${row.id}/details`}
                          >
                            <EyeOutlined style={{ color: "green" }} />
                          </IconButton>
                        </Tooltip>
                      )} */}
                        {/* {loading_countries_success && <EditProspect prospect={row} groups={groups} />} */}
                        <NewUserFile
                          wallet={row}
                          isEdition
                          handleSuccess={handleSuccess}
                          actionButton={() => (
                            <Tooltip title="Edit user file">
                              <IconButton>
                                <EditOutlined />
                              </IconButton>
                            </Tooltip>
                          )}
                        />
                        <Tooltip title="Delete">
                          <IconButton
                            onClick={() => setOpen({ open: true, id: row.id })}
                          >
                            <DeleteOutlined />
                          </IconButton>
                        </Tooltip>
                        <Dialog
                          open={open.open}
                          onClose={() => setOpen({ open: false, id: "" })}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">
                            {"Do you really want to delete?"}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              This action is irreversible. Are you sure to
                              continue ?
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              variant="default"
                              onClick={() => setOpen({ open: false, id: "" })}
                            >
                              Cancel
                            </Button>
                            <LoadingButton
                              loading={delete_propect_request}
                              variant="contained"
                              color="error"
                              // onClick={() => dispatch(deleteProspectEffect(row))}
                            >
                              Yes, delete
                            </LoadingButton>
                          </DialogActions>
                        </Dialog>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {!loading && transactions.length === 0 && (
          <Typography variant="body2" mt={5} sx={{textAlign: "center", width: "100%"}}> No Uploaded file for the moment</Typography>
        )}
      </Box>
    </Grid>
  );
}

import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseOutlined from "@ant-design/icons";

import { Link as RouterLink } from "react-router-dom";

// material-ui
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  MenuItem,
  Select,
} from "@mui/material";

// third party
import * as Yup from "yup";
import { Formik } from "formik";

// import { useHistory } from 'react-router';

// project import
// import FirebaseSocial from './FirebaseSocial';
import AnimateButton from "components/@extended/AnimateButton";

// assets
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

// actions
import { authLoginEffect } from "store/effects/authEffects";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import LoadingButton from "@mui/lab/LoadingButton";
import { API } from "services/axios";
import { URLS } from "services/urls/urls";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseOutlined style={{ color: "#000" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function NewBankInfos({
  wallet,
  isEdition,
  actionButton,
  handleSuccess,
}) {
  const [open, setOpen] = React.useState(false);
  const [qrCode, setQrCode] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const [showPassword, setShowPassword] = React.useState(false);
  const { login_request } = useSelector((state) => state.userAuth);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const navigate = useNavigate();
  const { userId } = useParams();

  const handleChangeQrCode = (event) => {
    setQrCode(event.target.files[0]);
  };

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div onClick={handleClickOpen}>{actionButton()}</div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {isEdition ? "Edit Bank infos" : "New bank infos"}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Formik
            initialValues={{
              accountType: wallet?.account_type ?? "",
              bankName: wallet?.bank_name ?? "",
              address: wallet?.bank_address ?? "",
              bankAccountName: wallet?.bank_account_name ?? "",
              swiftCode: wallet?.bank_swift_code ?? "",
              accountNumber: wallet?.account_number ?? "",
              submit: null,
            }}
            validationSchema={Yup.object().shape({
              accountType: Yup.string()
                .max(255)
                .required("This field is required"),
              bankName: Yup.string()
                .max(255)
                .required("This field is required"),
              address: Yup.string().max(255).required("This field is required"),
              bankAccountName: Yup.string().required("This field is required"),
              swiftCode: Yup.string().required("This field is required"),
              accountNumber: Yup.string().required("This field is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                setLoading(true);
                // const data = { ...values, type: "USER", user:  userId};
                const data = {
                    account_type: values?.accountType ?? "",
                    bank_name: values?.bankName ?? "",
                    bank_address: values?.address ?? "",
                    bank_account_name: values?.bankAccountName ?? "",
                    bank_swift_code: values?.swiftCode ?? "",
                    account_number: values?.accountNumber ?? "",
                    user: userId
                  }

                if (wallet && isEdition) {
                  API.patch(URLS.USERS.BANK_DETAILS.EDIT(wallet?.id), data).then(
                    (reps) => {
                      setStatus({ success: true });
                      setSubmitting(true);
                      if (handleSuccess) {
                        handleSuccess(reps.data);
                      }
                      setLoading(false);
                      handleClose();
                    }
                  ).catch ((err) => {
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                    setLoading(false);
                  });
                } else {
                  API.post(URLS.USERS.BANK_DETAILS.CREATE, data).then((reps) => {
                    setStatus({ success: true });
                    setSubmitting(true);
                    if (handleSuccess) {
                      handleSuccess(reps.data);
                    }
                    setLoading(false);
                    handleClose();
                  }).catch ((err) => {
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                    setLoading(false);
                  });
                }
              } catch (err) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
                setLoading(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="accountType-login">
                        Account Type
                      </InputLabel>
                      <Select
                        id="accountType-login"
                        // type="email"
                        value={values.accountType}
                        name="accountType"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Account type"
                        fullWidth
                        error={Boolean(
                          touched.accountType && errors.accountType
                        )}
                      >
                        <MenuItem value="SAVINGS">Savings</MenuItem>
                        <MenuItem value="CHECKING">Checking</MenuItem>
                        <MenuItem value="MONEY MARKET">Money market</MenuItem>
                      </Select>
                      {touched.accountType && errors.accountType && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-accountType-login"
                        >
                          {errors.accountType}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="bankName-login">
                        Bank name
                      </InputLabel>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(touched.bankName && errors.bankName)}
                        id="bankName-login"
                        // type={showPassword ? 'text' : 'password'}
                        value={values.bankName}
                        name="bankName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="bank name"
                      />
                      {touched.bankName && errors.bankName && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-bankName-login"
                        >
                          {errors.bankName}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="address-login">
                        Bank Address
                      </InputLabel>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(touched.address && errors.address)}
                        id="address-login"
                        // type={showPassword ? 'text' : 'password'}
                        value={values.address}
                        name="address"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Wallet or bank address"
                      />
                      {touched.address && errors.address && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-address-login"
                        >
                          {errors.address}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="swiftCode-login">
                        Swift code
                      </InputLabel>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(touched.swiftCode && errors.swiftCode)}
                        id="swiftCode-login"
                        // type={showPassword ? 'text' : 'password'}
                        value={values.swiftCode}
                        type="text"
                        min={0}
                        name="swiftCode"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Bank swift code"
                      />
                      {touched.swiftCode && errors.swiftCode && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-swiftCode-login"
                        >
                          {errors.swiftCode}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="accountNumber-login">
                        Account number
                      </InputLabel>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(
                          touched.accountNumber && errors.accountNumber
                        )}
                        id="accountNumber-login"
                        // type={showPassword ? 'text' : 'password'}
                        value={values.accountNumber}
                        type="text"
                        min={0}
                        name="accountNumber"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Bank account number"
                      />
                      {touched.accountNumber && errors.accountNumber && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-accountNumber-login"
                        >
                          {errors.accountNumber}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="bankAccountName-login">
                        Bank account name
                      </InputLabel>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(
                          touched.bankAccountName && errors.bankAccountName
                        )}
                        id="bankAccountName-login"
                        // type={showPassword ? 'text' : 'password'}
                        value={values.bankAccountName}
                        type="text"
                        min={0}
                        name="bankAccountName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Bank account name"
                      />
                      {touched.bankAccountName && errors.bankAccountName && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-bankAccountName-login"
                        >
                          {errors.bankAccountName}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  {errors.submit && (
                    <Grid item xs={12}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    sx={{ height: "0px", visibility: "hidden" }}
                  >
                    <AnimateButton>
                      <Button
                        disableElevation
                        disabled={login_request}
                        id="submitButton"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Submit
                        {/* {login_request && <CircularLo /> } */}
                      </Button>
                    </AnimateButton>
                  </Grid>
                  {/* <Grid item xs={12}>
                                <Divider>
                                    <Typography variant="caption"> Login with</Typography>
                                </Divider>
                            </Grid>
                            <Grid item xs={12}>
                                <FirebaseSocial />
                            </Grid> */}
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={loading}
            onClick={() => {
              const submitButton = document.getElementById("submitButton");
              if (submitButton) {
                submitButton.click();
              }
            }}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

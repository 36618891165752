import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseOutlined from "@ant-design/icons";

import { Link as RouterLink } from "react-router-dom";

// material-ui
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  MenuItem,
  Select,
} from "@mui/material";

// third party
import * as Yup from "yup";
import { Formik } from "formik";

// import { useHistory } from 'react-router';

// project import
// import FirebaseSocial from './FirebaseSocial';
import AnimateButton from "components/@extended/AnimateButton";

// assets
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

// actions
import { authLoginEffect } from "store/effects/authEffects";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import LoadingButton from "@mui/lab/LoadingButton";
import { API } from "services/axios";
import { URLS } from "services/urls/urls";
import { toast } from "react-toastify";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseOutlined style={{ color: "#000" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ClosePosition({
  wallet,
  isEdition,
  actionButton,
  handleSuccess,
  wallets,
}) {
  const [open, setOpen] = React.useState(false);
  const [qrCode, setQrCode] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const [showPassword, setShowPassword] = React.useState(false);
  const {
    login_request,
    auth: { user },
  } = useSelector((state) => state.userAuth);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const navigate = useNavigate();
  const { userId } = useParams();

  const handleChangeQrCode = (event) => {
    setQrCode(event.target.files[0]);
  };

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div onClick={handleClickOpen}>{actionButton()}</div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {isEdition ? "Edit position" : "New position"}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Formik
            initialValues={{
              comments: wallet?.comments ?? "",
              closingPrice: wallet?.closing_price ?? "0",
              commission: wallet?.commission ?? "",
              submit: null,
            }}
            validationSchema={Yup.object().shape({
              comments: Yup.string()
                .max(255)
                .notRequired("This field is required"),
              closingPrice: Yup.string().required("This field is required"),
              commission: Yup.string().required("This field is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting, resetForm }
            ) => {
              try {
                setLoading(true);
                // const data = { ...values, type: "USER", user:  userId};
                const data = {
                  closing_price: parseFloat(values.closingPrice),
                  commission: values.commission,
                  comments: values.comments,
                };
                API.patch(URLS.POSITIONS.EDIT(wallet.id), {
                  comments: values.comments,
                });

                API.put(URLS.POSITIONS.CLOSE(wallet.id), data)
                  .then((reps) => {
                    setStatus({ success: true });
                    setSubmitting(true);
                    if (handleSuccess) {
                      handleSuccess(reps.data);
                    }
                    resetForm()
                    setLoading(false);
                    handleClose();
                    toast.success("Position Closed successfuly");
                  })
                  .catch((err) => {
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                  });
              } catch (err) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="closingPrice-login">
                        Closing price
                      </InputLabel>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(
                          touched.closingPrice && errors.closingPrice
                        )}
                        id="closingPrice-login"
                        // type={showPassword ? 'text' : 'password'}
                        value={values.closingPrice}
                        type="number"
                        inputProps={{min: 0}}
                        name="closingPrice"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Position closing price"
                      />
                      {touched.closingPrice && errors.closingPrice && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-closingPrice-login"
                        >
                          {errors.closingPrice}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="commission-login">
                        Profit / Loss
                      </InputLabel>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(touched.commission && errors.commission)}
                        id="commission-login"
                        // type={showPassword ? 'text' : 'password'}
                        value={values.commission}
                        type="number"
                        min={0}
                        name="commission"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Profit / Loss"
                        inputProps={{min: 0, max: 100}}
                      />
                      {touched.commission && errors.commission && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-commission-login"
                        >
                          {errors.commission}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="comments-login">Comments</InputLabel>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(touched.comments && errors.comments)}
                        id="comments-login"
                        // type={showPassword ? 'text' : 'password'}
                        value={values.comments}
                        type="text"
                        multiline
                        minRows={4}
                        name="comments"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Position closing comment (The user will see this)"
                      />
                      {touched.comments && errors.comments && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-comments-login"
                        >
                          {errors.comments}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item md={6} xs={12} sx={{ mt: "0" }}></Grid>
                  {errors.submit && (
                    <Grid item xs={12}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    sx={{ height: "0px", visibility: "hidden" }}
                  >
                    <AnimateButton>
                      <Button
                        disableElevation
                        disabled={login_request}
                        id="submitButton"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Submit
                        {/* {login_request && <CircularLo /> } */}
                      </Button>
                    </AnimateButton>
                  </Grid>
                  {/* <Grid item xs={12}>
                                <Divider>
                                    <Typography variant="caption"> Login with</Typography>
                                </Divider>
                            </Grid>
                            <Grid item xs={12}>
                                <FirebaseSocial />
                            </Grid> */}
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={loading}
            onClick={() => {
              const submitButton = document.getElementById("submitButton");
              if (submitButton) {
                submitButton.click();
              }
            }}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

import {
    LOADING_MESSAGES_FAILED,
    LOADING_MESSAGES_REQUEST,
    LOADING_MESSAGES_SUCCESS,
    CREATE_MESSAGE_REQUEST,
    CREATE_MESSAGE_SUCCESS,
    CREATE_MESSAGE_FAILED,
    DELETE_MESSAGE_FAILED,
    DELETE_MESSAGE_SUCCESS,
    DELETE_MESSAGE_REQUEST,
    EDIT_MESSAGE_FAILED,
    EDIT_MESSAGE_REQUEST,
    EDIT_MESSAGE_SUCCESS,
    LOAD_MESSAGE_FAILED,
    LOAD_MESSAGE_REQUEST,
    LOAD_MESSAGE_SUCCESS,
    CREATE_CONVERSATIONS_REQUEST,
    CREATE_CONVERSATIONS_SUCCESS,
    CREATE_CONVERSATIONS_FAILED,
    LOADING_CONVERSATIONS_REQUEST,
    LOADING_CONVERSATIONS_SUCCESS,
    LOADING_CONVERSATIONS_FAILED,
  } from "../actions/messagesActions";
  
  const initialState = {
    loading_messages_request: true,
    loading_messages_success: false,
    loading_messages_failed: false,
    loading_message_request: false,
    loading_message_success: false,
    loading_message_failed: false,
    create_message_request: false,
    create_message_success: false,
    create_message_failed: false,
    edit_message_request: false,
    edit_message_success: false,
    edit_message_failed: false,
    delete_message_request: false,
    delete_message_success: false,
    delete_message_failed: false,
  
    loading_conversations_request: false,
    loading_conversations_success: false,
    loading_conversations_failed: false,
    create_conversations_request: false,
    create_conversations_success: false,
    create_conversations_failed: false,
  
    conversations: [],
    messagesList: {
      count: 0,
      pages: 1,
      actualPage: 1,
      results: [],
    },
    message: undefined,
  };
  
  export function MessagesReducers(state = initialState, action) {
    switch (action.type) {
      case LOADING_MESSAGES_REQUEST:
        return {
          ...state,
          loading_messages_request: true,
          loading_messages_success: false,
          loading_messages_failed: false,
        };
      case LOADING_MESSAGES_SUCCESS:
        return {
          ...state,
          loading_messages_request: false,
          loading_messages_success: true,
          loading_messages_failed: false,
          messagesList: { ...state.messagesList, results: action.payload.data },
        };
      case LOADING_MESSAGES_FAILED:
        return {
          ...state,
          loading_messages_request: false,
          loading_messages_success: false,
          loading_messages_failed: true,
        };
      case LOAD_MESSAGE_REQUEST:
        return {
          ...state,
          loading_message_request: true,
          loading_message_success: false,
          loading_message_failed: false,
        };
      case LOAD_MESSAGE_SUCCESS:
        return {
          ...state,
          loading_message_request: false,
          loading_message_success: true,
          loading_message_failed: false,
          message: action.payload,
        };
      case LOAD_MESSAGE_FAILED:
        return {
          ...state,
          loading_message_request: false,
          loading_message_success: false,
          loading_message_failed: true,
        };
      case CREATE_MESSAGE_REQUEST:
        return {
          ...state,
          create_message_request: true,
          create_message_success: false,
          create_message_failed: false,
        };
      case CREATE_MESSAGE_SUCCESS:
        return {
          ...state,
          create_message_request: false,
          create_message_success: true,
          create_message_failed: false,
          messagesList: {
            ...state.messagesList,
            results: [...state.messagesList.results, action.payload],
          },
        };
      case CREATE_MESSAGE_FAILED:
        return {
          ...state,
          create_message_request: false,
          create_message_success: false,
          create_message_failed: true,
        };
  
      case EDIT_MESSAGE_REQUEST:
        return {
          ...state,
          edit_message_request: true,
          edit_message_success: false,
          edit_message_failed: false,
        };
      case EDIT_MESSAGE_SUCCESS:
        return {
          ...state,
          edit_message_request: false,
          edit_message_success: true,
          edit_message_failed: false,
          messagesList: {
            ...state.messagesList,
            results: state.messagesList.results.map((item) => {
              if (item.id === action.id) {
                item = action.payload.data;
              }
  
              return item;
            }),
          },
        };
      case EDIT_MESSAGE_FAILED:
        return {
          ...state,
          edit_message_request: false,
          edit_message_success: false,
          edit_message_failed: true,
        };
  
      case DELETE_MESSAGE_REQUEST:
        return {
          ...state,
          delete_message_request: true,
          delete_message_success: false,
          delete_message_failed: false,
        };
      case DELETE_MESSAGE_SUCCESS:
        return {
          ...state,
          delete_message_request: false,
          delete_message_success: true,
          delete_message_failed: false,
          messagesList: {
            ...state.messagesList,
            results: state.messagesList.results.filter(
              (item) => item.id != action.payload.id
            ),
          },
        };
      case DELETE_MESSAGE_FAILED:
        return {
          ...state,
          create_message_request: false,
          create_message_success: false,
          create_message_failed: true,
        };
      // for conversations
      case CREATE_CONVERSATIONS_REQUEST:
        return {
          ...state,
          create_conversations_request: true,
          create_conversations_success: false,
          create_conversations_failed: false,
        };
      case CREATE_CONVERSATIONS_SUCCESS:
        return {
          ...state,
          create_conversations_request: false,
          create_conversations_success: true,
          create_conversations_failed: false,
          conversations: [...state.conversations, action.payload],
        };
      case CREATE_CONVERSATIONS_FAILED:
        return {
          ...state,
          create_conversations_request: false,
          create_conversations_success: false,
          create_conversations_failed: true,
        };
      case LOADING_CONVERSATIONS_REQUEST:
        return {
          ...state,
          loading_conversations_request: true,
          loading_conversations_success: false,
          loading_conversations_failed: false,
        };
      case LOADING_CONVERSATIONS_SUCCESS:
        return {
          ...state,
          loading_conversations_request: false,
          loading_conversations_success: true,
          loading_conversations_failed: false,
          conversations: action.payload,
        };
      case LOADING_CONVERSATIONS_FAILED:
        return {
          ...state,
          loading_conversations_request: false,
          loading_conversations_success: false,
          loading_conversations_failed: true,
        };
      default:
        return state;
    }
  }
  
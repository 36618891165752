import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseOutlined from "@ant-design/icons";

import { Link as RouterLink } from "react-router-dom";
// material-ui
import {
  Button,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  InputAdornment,
} from "@mui/material";

// third party
import * as Yup from "yup";
import { Formik } from "formik";

import AnimateButton from "components/@extended/AnimateButton";

// assets
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

// actions
import { authLoginEffect } from "store/effects/authEffects";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import LoadingButton from "@mui/lab/LoadingButton";
import { API } from "services/axios";
import { URLS } from "services/urls/urls";
import { toast } from "react-toastify";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseOutlined style={{ color: "#000" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function NewUser({
  toEdit,
  isEdition,
  isManager,
  isAdmin,
  isNormalUser,
  actionButton,
  handleSuccess,
}) {
  const [open, setOpen] = React.useState(false);
  const [qrCode, setQrCode] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const [showPassword, setShowPassword] = React.useState(false);
  const {
    login_request,
    auth: { user },
  } = useSelector((state) => state.userAuth);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const navigate = useNavigate();
  const { userId } = useParams();

  const handleChangeQrCode = (event) => {
    setQrCode(event.target.files[0]);
  };

  const dispatch = useDispatch();

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
};

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div onClick={handleClickOpen}>{actionButton()}</div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {isEdition ? "Edit Manager" : (isNormalUser ? "New User" : "New Manager")}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              phoneNumber: "",
              password: "",
              submit: null,
            }}
            validationSchema={Yup.object().shape({
              lastName: Yup.string()
                .max(255)
                .required("This field is required"),
              firstName: Yup.string().required("This field is required"),
              email: Yup.string().required("This field is required"),
              phoneNumber: Yup.string().required("This field is required"),
              password: Yup.string().required("This field is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                setLoading(true);
                // const data = { ...values, type: "USER", user:  userId};
                const data = {
                  email: values.email,
                  last_name: values.lastName,
                  first_name: values.firstName,
                  phone_number: values.phoneNumber,
                  password: values.password,
                  password2: values.password,
                  country: "",
                  city: "",
                  language: "EN",
                  gender: "MALE",
                  zip_code: "",
                  address: "",
                  default_currency: "EUR"
                };
                API.post(isNormalUser ? URLS.USERS.REGISTER : URLS.AUTHS.MANAGER_REGISTRATION, data)
                  .then((reps) => {
                    setStatus({ success: true });
                    setSubmitting(true);
                    if (handleSuccess) {
                      handleSuccess(reps.data);
                    }
                    setLoading(false);
                    handleClose();
                    
                    toast.success(isNormalUser ? "User created successfuly" : "Manager created successfuly");
                  })
                  .catch((err) => {
                    setStatus({ success: false });
                    setLoading(false);
                    setErrors({ submit: err?.response?.data?.detail ?? err.message });
                    setSubmitting(false);
                  });
              } catch (err) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="firstName-login">
                        First name
                      </InputLabel>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(
                          touched.firstName && errors.firstName
                        )}
                        id="firstName-login"
                        // type={showPassword ? 'text' : 'password'}
                        value={values.firstName}
                        type="text"
                        min={0}
                        name="firstName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="First name"
                      />
                      {touched.firstName && errors.firstName && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-firstName-login"
                        >
                          {errors.firstName}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="lastName-login">Last Name</InputLabel>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(touched.lastName && errors.lastName)}
                        id="lastName-login"
                        // type={showPassword ? 'text' : 'password'}
                        value={values.lastName}
                        type="text"
                        name="lastName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Last name"
                      />
                      {touched.lastName && errors.lastName && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-lastName-login"
                        >
                          {errors.lastName}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="email-login">
                        Email
                      </InputLabel>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(touched.email && errors.email)}
                        id="email-login"
                        // type={showPassword ? 'text' : 'password'}
                        value={values.email}
                        type="email"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Email address"
                      />
                      {touched.email && errors.email && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-email-login"
                        >
                          {errors.email}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="phoneNumber-login">
                        Phone Number
                      </InputLabel>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                        id="phoneNumber-login"
                        // type={showPassword ? 'text' : 'password'}
                        value={values.phoneNumber}
                        type="text"
                        name="phoneNumber"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Phone number"
                      />
                      {touched.phoneNumber && errors.phoneNumber && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-phoneNumber-login"
                        >
                          {errors.phoneNumber}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="password-login">
                        Password
                      </InputLabel>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(touched.password && errors.password)}
                        id="password-login"
                        // type={showPassword ? 'text' : 'password'}
                        value={values.password}
                        type={showPassword ? 'text' : 'password'}
                        min={0}
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Password"
                        endAdornment={
                          <InputAdornment position="end">
                              <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                  size="large"
                              >
                                  {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                              </IconButton>
                          </InputAdornment>
                      }
                      />
                      {touched.password && errors.password && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-password-login"
                        >
                          {errors.password}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item md={6} xs={12} sx={{ mt: "0" }}></Grid>
                  {errors.submit && (
                    <Grid item xs={12}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    sx={{ height: "0px", visibility: "hidden" }}
                  >
                    <AnimateButton>
                      <Button
                        disableElevation
                        disabled={login_request}
                        id="submitButton"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Submit
                        {/* {login_request && <CircularLo /> } */}
                      </Button>
                    </AnimateButton>
                  </Grid>
                  {/* <Grid item xs={12}>
                                <Divider>
                                    <Typography variant="caption"> Login with</Typography>
                                </Divider>
                            </Grid>
                            <Grid item xs={12}>
                                <FirebaseSocial />
                            </Grid> */}
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={loading}
            onClick={() => {
              const submitButton = document.getElementById("submitButton");
              if (submitButton) {
                submitButton.click();
              }
            }}
          >
            Create
          </LoadingButton>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

export const LOADING_MESSAGES_REQUEST = 'LOADING_MESSAGES_REQUEST';
export const LOADING_MESSAGES_SUCCESS = 'LOADING_MESSAGES_SUCCESS';
export const LOADING_MESSAGES_FAILED = 'LOADING_MESSAGES_FAILED';


export const CREATE_MESSAGE_REQUEST = 'CREATE_MESSAGE_REQUEST';
export const CREATE_MESSAGE_SUCCESS = 'CREATE_MESSAGE_SUCCESS';
export const CREATE_MESSAGE_FAILED = 'CREATE_MESSAGE_FAILED';

export const EDIT_MESSAGE_REQUEST = 'EDIT_MESSAGE_REQUEST';
export const EDIT_MESSAGE_SUCCESS = 'EDIT_MESSAGE_SUCCESS';
export const EDIT_MESSAGE_FAILED = 'EDIT_MESSAGE_FAILED';

export const DELETE_MESSAGE_REQUEST = 'DELETE_MESSAGE_REQUEST';
export const DELETE_MESSAGE_SUCCESS = 'DELETE_MESSAGE_SUCCESS';
export const DELETE_MESSAGE_FAILED = 'DELETE_MESSAGE_FAILED';

export const LOAD_MESSAGE_REQUEST = 'LOAD_MESSAGE_REQUEST';
export const LOAD_MESSAGE_SUCCESS = 'LOAD_MESSAGE_SUCCESS';
export const LOAD_MESSAGE_FAILED = 'LOAD_MESSAGE_FAILED';

// Conversations
export const LOADING_CONVERSATIONS_REQUEST = 'LOADING_CONVERSATIONS_REQUEST';
export const LOADING_CONVERSATIONS_SUCCESS = 'LOADING_CONVERSATIONS_SUCCESS';
export const LOADING_CONVERSATIONS_FAILED = 'LOADING_CONVERSATIONS_FAILED';


export const CREATE_CONVERSATIONS_REQUEST = 'CREATE_CONVERSATIONS_REQUEST';
export const CREATE_CONVERSATIONS_SUCCESS = 'CREATE_CONVERSATIONS_SUCCESS';
export const CREATE_CONVERSATIONS_FAILED = 'CREATE_CONVERSATIONS_FAILED';


export function loadingMessagesRequest(data) {
    return {
        type: LOADING_MESSAGES_REQUEST,
    };
}

export function loadingMessagesSuccess(data, isAdmin) {
    return {
        type: LOADING_MESSAGES_SUCCESS,
        payload: {data, isAdmin},
    };
}

export function loadingMessagesFailed() {
    return {
        type: LOADING_MESSAGES_FAILED,
    };
}


// Create message action

export function createMessageRequest(data) {
    return {
        type: CREATE_MESSAGE_REQUEST,
    };
}

export function createMessageSuccess(data) {
    return {
        type: CREATE_MESSAGE_SUCCESS,
        payload: data,
    };
}

export function createMessageFailed() {
    return {
        type: CREATE_MESSAGE_FAILED,
    };
}

// Edit message actions

export function editMessageRequest() {
    return {
        type: EDIT_MESSAGE_REQUEST,
    };
}

export function editMessageSuccess(id, data) {
    return {
        type: EDIT_MESSAGE_SUCCESS,
        payload: {id, data},
    };
}

export function editMessageFailed() {
    return {
        type: EDIT_MESSAGE_FAILED,
    };
}

// Delete Message actions 

export function deleteMessageRequest() {
    return {
        type: DELETE_MESSAGE_REQUEST,
    };
}

export function deleteMessageSuccess(id) {
    return {
        type: DELETE_MESSAGE_SUCCESS,
        payload: {"id": id},
    };
}

export function deleteMessageFailed() {
    return {
        type: DELETE_MESSAGE_FAILED,
    };
}
// Load Message actions 

export function loadMessageRequest() {
    return {
        type: LOAD_MESSAGE_REQUEST,
    };
}

export function loadMessageSuccess(message) {
    return {
        type: LOAD_MESSAGE_SUCCESS,
        payload: message,
    };
}

export function loadMessageFailed() {
    return {
        type: LOAD_MESSAGE_FAILED,
    };
}


/* Conversations */

export function loadConversationsRequest() {
    return {
        type: LOADING_CONVERSATIONS_REQUEST,
    };
}

export function loadConversationsSuccess(conversations) {
    return {
        type: LOADING_CONVERSATIONS_SUCCESS,
        payload: conversations,
    };
}

export function loadConversationsFailed() {
    return {
        type: LOADING_CONVERSATIONS_FAILED,
    };
}


export function createConversationRequest() {
    return {
        type: CREATE_CONVERSATIONS_REQUEST,
    };
}

export function createConversationSuccess(conversation) {
    return {
        type: CREATE_CONVERSATIONS_SUCCESS,
        payload: conversation,
    };
}

export function createConversationFailed() {
    return {
        type: CREATE_CONVERSATIONS_FAILED,
    };
}

import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";

import Typography from "@mui/material/Typography";
import { API } from "services/axios";
import { URLS } from "services/urls/urls";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseOutlined />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function NewCryptoDepositDetails({
  onSuccess,
  isEdition,
  toEdit,
}) {
  const [open, setOpen] = React.useState(false);
  const [creating, setCreating] = React.useState(false);

  const {
    auth: { user },
  } = useSelector((state) => state.userAuth);

  const [walletAddress, setWalletAddress] = React.useState(
    isEdition ? toEdit?.address : ""
  );
  const [walletType, setWalletType] = React.useState(
    isEdition ? toEdit?.wallet_type : ""
  );
  const [qrCode, setQrCode] = React.useState();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleCreate = () => {
    if (walletAddress && walletType) {
      setCreating(true);
      let data = new FormData();
      data.append("wallet_type", walletType);
      data.append("address", walletAddress);
      data.append("created_by", user.id);
      if (qrCode) {
        data.append("qr_code", qrCode);
      }

      if (isEdition) {
        API.patch(URLS.WALLETS.DEPOSITS.CRYPTO_EDIT(toEdit?.id), data)
          .then((resp) => {
            onSuccess(resp.data, true);
            handleClose();
            toast.success(
              `Crypto deposit details successfuly ${
                isEdition ? "edited" : "added"
              }.`
            );
          })
          .catch((error) => {
            setCreating(false);
            toast.error(
              error?.response?.data?.detail ??
                `Crypto details ${isEdition ? "editing" : "creating"} error.`
            );
          });
      } else {
        API.post(URLS.WALLETS.DEPOSITS.CRYPTO, data)
          .then((resp) => {
            onSuccess(resp.data);
            handleClose();
            toast.success("Crypto deposit details successfuly added.");
          })
          .catch((error) => {
            setCreating(false);
            toast.error(
              error?.response?.data?.detail ?? "Crypto details creating error."
            );
          });
      }
    }
  };

  const handleChangeFile = (event) => {
    setQrCode(event.target.files[0]);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {isEdition ? (
        <IconButton
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
        >
          <EditOutlined />
        </IconButton>
      ) : (
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          Create new
        </Button>
      )}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth={"sm"}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Crypto deposit informations
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item md={6} sm={12}>
              <TextField
                sx={{ width: "100%" }}
                id="outlined-account-name"
                label="Wallet address"
                value={walletAddress}
                onChange={(event) => setWalletAddress(event.target.value)}
                variant="outlined"
                size="md"
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <Grid>
                <TextField
                  select
                  sx={{ width: "100%" }}
                  id="outlined-wallet-type"
                  label="Wallet type"
                  variant="outlined"
                  value={walletType}
                  onChange={(event) => setWalletType(event.target.value)}
                >
                  <MenuItem value={"ERC20"}> ERC20 </MenuItem>
                  <MenuItem value={"TRC20"}> TRC20 </MenuItem>
                </TextField>
              </Grid>
            </Grid>
            <Grid item md={6} sm={12}>
              <Grid>
                <TextField
                  sx={{ width: "100%" }}
                  id="outlined-qr-code"
                  //   label="Qr code"
                  variant="outlined"
                  type="file"
                  //   value={qrCode}
                  onChange={handleChangeFile}
                />
                {isEdition && (
                  <Box mt={4}>
                    <Typography>previous QR code</Typography>
                    <img
                      width={"70px"}
                      src={toEdit?.crypto_deposits?.qr_code}
                      alt=""
                    />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton
            loading={creating}
            variant="contained"
            color={"primary"}
            onClick={handleCreate}
          >
            {isEdition ? "Save changes" : "Create"}
          </LoadingButton>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

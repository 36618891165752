import * as React from "react";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { MoreOutlined } from "@ant-design/icons";
import SelectUsers from "components/selectUsers/SelectUsers";
import { toast } from "react-toastify";
import { API } from "services/axios";
import { URLS } from "services/urls/urls";
import { useDispatch, useSelector } from "react-redux";
import { convertProspectEffect } from "store/effects/prospectEffects";

const ITEM_HEIGHT = 48;

export default function UsersListActions({ user, view }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [assigningToManager, setAssigningToManager] = React.useState(false);
  const [assigningToTeam, setAssigningToTeam] = React.useState(false);

  const { convert_propect_request } = useSelector((state) => state.prospects);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConveertProspect = () => {
    dispatch(convertProspectEffect(user));
  };

  const handleAssignUserToManager = (managers) => {
    setAssigningToManager(true);
    if(!user.managed_by){
      handleConveertProspect()
    }
    const id = toast.loading("Assigning to a manager...");
    API.patch(URLS.USERS.ASSIGN_TO_MANAGER(user.id), {
      ...user,
      managed_by: managers[0].id,
    })
      .then((resp) => {
        setAssigningToManager(true);
        toast.update(id, {
          render: "User successfully assigned to manager.",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
      })
      .catch((error) => {
        setAssigningToManager(true);
        toast.update(id, {
          render: "Something went wrong",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
      });
  };

  const handleNameUserAsTeamManager = (managers) => {
    setAssigningToManager(true);
    const id = toast.loading("Assigning to a team...");
    API.put(URLS.USERS.NAME_AS_TEAM_MANAGER(user.id), {
      team_leader: user.id,
      team_members: managers.map((item) => item.id),
    })
      .then((resp) => {
        setAssigningToManager(true);
        toast.update(id, {
          render: "User successfully assigned to team.",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
      })
      .catch((error) => {
        setAssigningToManager(true);
        toast.update(id, {
          render: "Something went wrong",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
      });
  };

  const handleAssignUserToTeam = (managers) => {
    setAssigningToTeam(true);
    const id = toast.loading("Assigning to a manager...");
    API.put(URLS.USERS.NAME_AS_TEAM_MANAGER(managers[0].id), {
      team_leader: managers[0].id,
      team_members: [user.id],
    })
      .then((resp) => {
        setAssigningToTeam(true);
        toast.update(id, {
          render: "User successfully assigned to manager.",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
      })
      .catch((error) => {
        setAssigningToTeam(true);
        toast.update(id, {
          render: "Something went wrong",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
      });
  };
  const handleLockUser = () => {
    handleClose();
    const id = toast.loading("Locking user...");
    API.put(URLS.USERS.LOCK_UNLOCK_USER(user.id), {
      ...user,
      phone_number: "333",
      is_active: user.is_active ? false : true,
    })
      .then((resp) => {
        toast.update(id, {
          render: `User successfully ${
            user.is_active ? "Unlocked" : "locked"
          }.`,
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
        user.is_active = !user.is_active;
      })
      .catch((error) => {
        toast.update(id, {
          render: "Something went wrong",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
      });
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreOutlined />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            // width: "30ch",
          },
        }}
      >
        {view === "PROSPECTS" && (
          <MenuItem
            selected={false}
            onClick={handleConveertProspect}
            disabled={convert_propect_request}
          >
            Convert the prospect
          </MenuItem>
        )}
        {!user.is_manager && <MenuItem selected={false} disabled={assigningToManager}>
          <SelectUsers
            many={false}
            actionButton={() => <span>Assign to a manager</span>}
            dialogTitle={"Select one managers."}
            actionLabel={"Assign"}
            onUserSelect={handleAssignUserToManager}
          />
        </MenuItem>}
        {user.is_manager && (
          <MenuItem selected={false}>
            <SelectUsers
              many={true}
              actionButton={() => <span>Name as team manager</span>}
              dialogTitle={"Select one or many managers to add in the group"}
              actionLabel={"Name as team manager"}
              onUserSelect={handleNameUserAsTeamManager}
            />
          </MenuItem>
        )}
        {user.is_manager && (
          <MenuItem selected={false} disabled={assigningToTeam}>
            <SelectUsers
              many={false}
              userType={"TEAM_LEADER"}
              actionButton={() => <span>Assign to a team</span>}
              dialogTitle={"Select one a team where you want to affect user."}
              actionLabel={"Assign to a team"}
              onUserSelect={handleAssignUserToTeam}
            />
          </MenuItem>
        )}
        <Divider />
        {view != "PROSPECTS" && (
          <MenuItem color="warning" selected={false} onClick={handleLockUser}>
            {!user.is_active ? "unlock user" : "Lock user"}
          </MenuItem>
        )}
        {/* <MenuItem selected={false} onClick={handleClose}>
          Delete
        </MenuItem> */}
      </Menu>
    </div>
  );
}

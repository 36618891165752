// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    login_request: false,
    login_success: false,
    login_failed: false,
    auth: undefined
};

// ==============================|| SLICE - MENU ||============================== //

const userAuth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginUserRequest(state, action) {
            state.login_request = true;
            state.login_success = false;
            state.login_failed = false;
        },
        loginUserSuccess(state, action) {
            state.login_request = false;
            state.login_success = true;
            state.login_failed = false;
            state.auth = action.payload;
        },
        loginUserFailed(state, action) {
            state.login_request = false;
            state.login_success = false;
            state.login_failed = true;
        }
    }
});

export default userAuth.reducer;

export const { loginUserRequest, loginUserSuccess, loginUserFailed } = userAuth.actions;

import * as React from 'react';
import {
    Stack,
    Box,
    OutlinedInput,
    Grid,
    Dialog,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Autocomplete,
    InputLabel,
    TextField,
    FormHelperText,
    IconButton
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// import FirebaseSocial from './FirebaseSocial';
import AnimateButton from 'components/@extended/AnimateButton';
import { useDispatch, useSelector } from 'react-redux';
import { createProspectEffect, editProspectEffect } from 'store/effects/prospectEffects';
import _ from 'lodash';
import { toast } from 'react-toastify';
// Icons
import { EditOutlined } from '@ant-design/icons';

export default function EditProspect({ prospect, groups }) {
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');
    const [country, setCountry] = React.useState(prospect?.countryDetails);

    const { edit_propect_request } = useSelector((state) => state.prospects);
    const { loading_countries_request, countriesList } = useSelector((state) => state.countries);

    const canEdit =
        _.filter(groups, (item) => item.name.includes('managere')).length > 0 ||
        _.filter(groups, (item) => item.name.includes('admine')).length > 0;

    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const dispatch = useDispatch();

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }

            //     // Set country
            //     const country = _.filter(countriesList, (item) => item.url.includes(`/${prospect.country}/`))[0];
            //     setCountry(country);
            //     if (prospect.countryDetails) {
            //         setCountry(prospect.countryDetails);
            //     }
        }
    }, [open]);

    return (
        <div>
            <IconButton onClick={handleClickOpen('body')}>
                <EditOutlined />
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">Prospect registration</DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                    <Grid id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
                        <Formik
                            initialValues={{
                                email: prospect.email ?? '',
                                firstName: prospect.first_name ?? '',
                                lastName: prospect.last_name ?? '',
                                phoneNumber: prospect.phone_number ?? '',
                                status: prospect.status ?? '',
                                message: prospect.message ?? '',
                                submit: null
                            }}
                            validationSchema={Yup.object().shape({
                                email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                                firstName: Yup.string().max(255).required('First name is required'),
                                lastName: Yup.string().max(255).required('Last name is required'),
                                phoneNumber: Yup.string().max(255).required('Phone is required'),
                                status: Yup.string().max(255).required('Status is required'),
                                message: Yup.string().max(5000).required('Message is required')
                            })}
                            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                                setStatus({ success: false });
                                setSubmitting(false);
                                if (country) {
                                    let countryId = country.url.split('/');
                                    countryId = countryId[countryId.length - 2];
                                    const newPropspectDatas = {
                                        id: prospect.id,
                                        country: countryId,
                                        first_name: values.firstName,
                                        last_name: values.lastName,
                                        email: values.email,
                                        phone_number: values.phoneNumber,
                                        status: values.status,
                                        message: values.message
                                    };
                                    dispatch(editProspectEffect(newPropspectDatas, handleClose));
                                } else {
                                    toast.error('Please choose a country and try again...');
                                }
                            }}
                        >
                            {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                                <form noValidate onSubmit={handleSubmit}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <Stack spacing={1}>
                                                <InputLabel htmlFor="email-login">First name</InputLabel>
                                                <OutlinedInput
                                                    id="firstName"
                                                    type="text"
                                                    value={values.firstName}
                                                    name="firstName"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    placeholder="Enter First name"
                                                    fullWidth
                                                    disabled={!canEdit}
                                                    error={Boolean(touched.firstName && errors.firstName)}
                                                />
                                                {touched.firstName && errors.firstName && (
                                                    <FormHelperText error id="standard-weight-helper-text-email-login">
                                                        {errors.firstName}
                                                    </FormHelperText>
                                                )}
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Stack spacing={1}>
                                                <InputLabel htmlFor="email-login">Last name</InputLabel>
                                                <OutlinedInput
                                                    id="lastName"
                                                    type="text"
                                                    value={values.lastName}
                                                    name="lastName"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    disabled={!canEdit}
                                                    placeholder="Enter First name"
                                                    fullWidth
                                                    error={Boolean(touched.lastName && errors.lastName)}
                                                />
                                                {touched.lastName && errors.lastName && (
                                                    <FormHelperText error id="standard-weight-helper-text-email-login">
                                                        {errors.lastName}
                                                    </FormHelperText>
                                                )}
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Stack spacing={1}>
                                                <InputLabel htmlFor="password-login">Email Address</InputLabel>
                                                <OutlinedInput
                                                    id="email-login"
                                                    type="email"
                                                    value={values.email}
                                                    name="email"
                                                    disabled={!canEdit}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    placeholder="Enter email address"
                                                    fullWidth
                                                    error={Boolean(touched.email && errors.email)}
                                                />
                                                {touched.password && errors.password && (
                                                    <FormHelperText error id="standard-weight-helper-text-password-login">
                                                        {errors.password}
                                                    </FormHelperText>
                                                )}
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Stack spacing={1}>
                                                <InputLabel htmlFor="email-login">Phone number</InputLabel>
                                                <OutlinedInput
                                                    id="phoneNumber"
                                                    type="text"
                                                    value={values.phoneNumber}
                                                    name="phoneNumber"
                                                    disabled={!canEdit}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    placeholder="Enter Phone number"
                                                    fullWidth
                                                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                                                />
                                                {touched.phoneNumber && errors.phoneNumber && (
                                                    <FormHelperText error id="standard-weight-helper-text-email-login">
                                                        {errors.phoneNumber}
                                                    </FormHelperText>
                                                )}
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Stack spacing={1}>
                                                <InputLabel htmlFor="email-login">Country</InputLabel>
                                                <Autocomplete
                                                    id="country-select-demo"
                                                    options={countriesList}
                                                    value={country}
                                                    disabled={!canEdit}
                                                    autoHighlight
                                                    loading={loading_countries_request}
                                                    getOptionLabel={(option) => option.name}
                                                    onChange={(event, newValue) => {
                                                        setCountry(newValue);
                                                    }}
                                                    renderOption={(props, option) => (
                                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                            <img
                                                                loading="lazy"
                                                                width="20"
                                                                src={`https://flagcdn.com/w20/${option.code2.toLowerCase()}.png`}
                                                                srcSet={`https://flagcdn.com/w40/${option.code2.toLowerCase()}.png 2x`}
                                                                alt=""
                                                            />
                                                            {option.name} ({option.code2}) +{option.phone}
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            disabled={!canEdit}
                                                            label="Choose a country"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: 'new-password' // disable autocomplete and autofill
                                                            }}
                                                        />
                                                    )}
                                                />
                                                {touched.phoneNumber && errors.phoneNumber && (
                                                    <FormHelperText error id="standard-weight-helper-text-email-login">
                                                        {errors.phoneNumber}
                                                    </FormHelperText>
                                                )}
                                            </Stack>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Stack spacing={1}>
                                                <InputLabel htmlFor="email-login">Status</InputLabel>
                                                <FormControl fullWidth>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={values.status}
                                                        name="status"
                                                        label="Status"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        error={Boolean(touched.status && errors.status)}
                                                    >
                                                        {[
                                                            'ANSWER',
                                                            'NO ANSWER 1',
                                                            'NO ANSWER 2',
                                                            'NO ANSWER 3',
                                                            'NO ANSWER 4',
                                                            'NO ANSWER 5',
                                                            'NEVER ANSWER'
                                                        ].map((item, index) => (
                                                            <MenuItem key={'menu-item-' + index} value={item}>
                                                                {item}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                {/* <OutlinedInput
                                                    id="phoneNumber"
                                                    type="text"
                                                    value={values.phoneNumber}
                                                    name="phoneNumber"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    placeholder="Enter Phone number"
                                                    fullWidth
                                                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                                                /> */}
                                                {touched.status && errors.status && (
                                                    <FormHelperText error id="standard-weight-helper-text-email-login">
                                                        {errors.status}
                                                    </FormHelperText>
                                                )}
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Stack spacing={1}>
                                                <InputLabel htmlFor="email-login">Message</InputLabel>
                                                <OutlinedInput
                                                    id="message"
                                                    type="text"
                                                    value={values.message}
                                                    name="message"
                                                    multiline
                                                    minRows={6}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    placeholder="Write a note for this prospect"
                                                    fullWidth
                                                    error={Boolean(touched.message && errors.message)}
                                                />
                                                {touched.message && errors.message && (
                                                    <FormHelperText error id="standard-weight-helper-text-email-login">
                                                        {errors.message}
                                                    </FormHelperText>
                                                )}
                                            </Stack>
                                        </Grid>
                                        {errors.submit && (
                                            <Grid item xs={12}>
                                                <FormHelperText error>{errors.submit}</FormHelperText>
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <AnimateButton>
                                                <Button
                                                    disableElevation
                                                    fullWidth
                                                    size="large"
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    sx={{ visibility: 'hidden' }}
                                                    id="submit"
                                                >
                                                    {/* {login_request && <CircularLo /> } */}
                                                </Button>
                                            </AnimateButton>
                                        </Grid>
                                        {/* <Grid item xs={12}>
                                <Divider>
                                    <Typography variant="caption"> Login with</Typography>
                                </Divider>
                            </Grid>
                            <Grid item xs={12}>
                                <FirebaseSocial />
                            </Grid> */}
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <LoadingButton
                        loading={edit_propect_request}
                        onClick={() => {
                            let submitBtn = document.getElementById('submit');
                            if (submitBtn) {
                                submitBtn.click();
                            }
                        }}
                        variant="contained"
                        color="primary"
                    >
                        Update
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

// project import
import pages from './pages';
import dashboard from './dashboard';
import utilities from './utilities';
import support from './support';
import admin from './admin';
import _ from 'lodash';
import SecureLS from 'secure-ls';
// Secure local storage

let ls = new SecureLS({ encodingType: 'aes' });

// ==============================|| MENU ITEMS ||============================== //

const { groups } = ls.get('user-details');
const menuItems = {
    items: _.filter(groups, { name: 'admin' }).length > 0 ? [dashboard, admin] : [dashboard]
    // items: [dashboard, pages, utilities, support]
};

export default menuItems;

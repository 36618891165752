import { API } from "services/axios";
import { URLS } from "services/urls/urls";
import {
  loginUserFailed,
  loginUserRequest,
  loginUserSuccess,
} from "store/reducers/userAuth";
import { toast } from "react-toastify";
import SecureLS from "secure-ls";
import _ from "lodash";

let ls = new SecureLS({ encodingType: "aes" });

export const authLoginEffect = (datas, navigate) => {
  return (dispatch) => {
    dispatch(loginUserRequest());
    API.post(URLS.AUTHS.LOGIN, datas)
      .then((resp) => {
        const { data } = resp;
        dispatch(loginUserSuccess(data));
        ls.set("user-details", data);
        const isAdmin =
          _.filter(data?.user?.groups, { name: "admin" }).length > 0;
        if (isAdmin) {
          navigate("/");
        } else {
          navigate("/users/list");
        }
        window.location.reload();
      })
      .catch((error) => {
        toast.error(
          error.response.data.detail ?? "Login failed with provided credentials"
        );
        dispatch(loginUserFailed());
      });
  };
};

import {
  Grid,
  Typography,
  Stack,
  IconButton,
  Menu,
  Fade,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";

import PropTypes from "prop-types";
import React, { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import { API } from "services/axios";
import { URLS } from "services/urls/urls";
import { MoreOutlined } from "@ant-design/icons";
import { LinearProgress } from "@mui/material";
import Cards from "react-credit-cards";

// Icons
import HideIfNotAdmin from "components/auth/HideIfNotAdmin";
import Swal from "sweetalert2";
import 'react-credit-cards/es/styles-compiled.css';


function WalletMenu({ wallet, handleSuccess }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ position: "absolute", bottom: "1rem", right: "1rem" }}
      >
        <MoreOutlined style={{ color: "#fff" }} />
      </IconButton>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {/* <NewWallet
            handleSuccess={handleSuccess}
            wallet={wallet}
            isEdition={true}
            actionButton={() => <MenuItem>Update</MenuItem>}
          /> */}
      </Menu>
    </>
  );
}

// ==============================|| PROSPECTS LIST ||============================== //

function OrderTableHead({ handleSelectAll, isAllSelected }) {
  return (
    <TableHead>
      <TableRow>
        <TableCell>
          {" "}
          <Checkbox
            checked={isAllSelected}
            onClick={() => handleSelectAll(isAllSelected)}
          />
        </TableCell>
        <TableCell>Reference</TableCell>
        <TableCell>Amount</TableCell>
        <TableCell>Status</TableCell>
        <TableCell>Date</TableCell>
      </TableRow>
    </TableHead>
  );
}

OrderTableHead.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
};

export default function CreditCards() {
  const { userId } = useParams();
  const [wallets, setWallets] = useState([]);
  const [hover, setHover] = useState("");

  const [loading, setLoading] = useState(true);
  const [selectedWallet, setSelectedWallet] = useState();
  const toastId = React.useRef(null);

  const handleSuccess = (wallet) => {
    API.get(URLS.WALLETS.GLOBALS).then((resp) => {
      setWallets(resp.data);
    });
  };
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Swal.fire(
        //   'Deleted!',
        //   'Your file has been deleted.',
        //   'success'
        // )
        toastId.current = toast("Deleting...", {
          autoClose: false,
          closeButton: true,
        });

        API.delete(URLS.WALLETS.DELETE(selectedWallet.id))
          .then((resp) => {
            toast.update(toastId.current, {
              render: "Wallet successfuly deleted.",
              type: toast.TYPE.SUCCESS,
              autoClose: 5000,
              toastId: toastId,
            });
            handleSuccess();
          })
          .catch((error) => {
            toast.update(toastId.current, {
              render:
                error?.response?.detail ?? "Error while deleting the wallet.",
              type: toast.TYPE.ERROR,
              autoClose: 5000,
              toastId: toastId,
            });
          });
      }
    });
  };

  useEffect(() => {
    API.get(URLS.USERS.CC)
      .then((resp) => {
        setWallets(resp.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  return (
    <Grid container spacing={3}>
      <HideIfNotAdmin>
        <Grid item md={12}>
          <Stack justifyContent="end">
            {/* <NewWallet
                handleSuccess={handleSuccess}
                actionButton={() => (
                  <Button
                    variant="contained"
                    sx={{ width: "200px", marginLeft: "auto" }}
                    color="primary"
                  >
                    New wallet
                  </Button>
                )}
              /> */}
          </Stack>
        </Grid>
      </HideIfNotAdmin>
      <Grid item md={12}>
        <Grid>{loading && <LinearProgress />}</Grid>
      </Grid>
      <Grid item md={12} p={3}>
        <Grid>
          <div class="crypt-deepblue-segment p-2">
            {!loading && wallets?.length === 0 && (
              <Typography sx={{ textAlign: "left" }} variant="body2">
                No Credit card for this user
              </Typography>
            )}
            {/* <form class="crypt-dash-search">
                          <input type="search" placeholder="Search..." name="s" class="crypt-big-search"/>
                          <button type="submit">
                              <i class="pe-7s-search"></i>
                          </button>
                      </form> */}
            <Grid container spacing={2}>
              {wallets?.map((wallet) => {
                return (
                  <Grid item md={3} key={wallet.id}>
                    <Grid
                      onMouseEnter = {
                        () => {
                          // console.log(wallet.id)
                          setHover(wallet.id)
                        }
                      }
                      onMouseLeave = {
                        () => {
                          // console.log(wallet.id)
                          setHover("")
                        }
                      }
                    > 
                      <Cards 
                        cvc={wallet?.card_cvv}
                        expiry={String(wallet?.card_date).replaceAll(" ", "")}
                        // expiry={"02/33"}
                        focused={hover === wallet?.id ? 'cvc': 'number'}
                        name={wallet?.card_holder ?? ""}
                        number={wallet?.card_number}
                        />
                        <Typography><strong>Added by:</strong> {wallet?.user?.first_name + " " + wallet?.user?.last_name} </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

import { API } from "services/axios"
import { URLS } from "services/urls/urls"

const FIATS = ["USD", "EUR"];

export const handleCreateWalletDepositInfo = (wallet, depositInfos, handleSuccess) => {

    API.post(FIATS.includes(wallet.currency) ? URLS.WALLETS.DEPOSITS.BANK : URLS.WALLETS.DEPOSITS.CRYPTO, depositInfos).then((resp) => {
        if(handleSuccess){
            handleSuccess();
        }
    })
}

export const handleEditWalletDepositInfo = (id, wallet, depositInfos, handleSuccess) => {

    API.patch(FIATS.includes(wallet.currency) ? URLS.WALLETS.DEPOSITS.BANK_EDIT(id) : URLS.WALLETS.DEPOSITS.CRYPTO_EDIT(id), depositInfos).then((resp) => {
        if(handleSuccess){
            handleSuccess();
        }
    })
}

